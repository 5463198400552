import {Howl} from 'howler';


class RadioPlaybackState {
    desired = 'stopped' // stopped, playing
    actual = 'stopped'  // stopped, loading, playing
    callback

    /**
     * @param {Function} callback
     */
    constructor(callback) {
        this.callback = callback
    }

    /**
     * @param {String|null} desired
     * @param {String|null} actual
     */
    setState(desired = null, actual = null) {
        if (desired !== null) {
            this.desired = desired
        }
        if (actual !== null) {
            this.actual = actual
        }
        console.debug(`RadioPlaybackState: desired=${this.desired} actual=${this.actual}`)
        this.callback(this.desired, this.actual)
    }
}


class RadioPlayer {
    url
    howl
    state

    /**
     * @param {String} url
     * @param {Function} onStateChange
     */
    constructor(url, onStateChange) {
        this.url = url
        this.state = new RadioPlaybackState(onStateChange)
    }

    play() {
        this.state.setState('playing', 'loading')
        if (!this.howl) {
            this.howl = this._createHowl()
        }
        this.howl.play()
    }

    stop() {
        this.state.setState('stopped', null)
        if (this.howl) {
            this.howl.unload()
            this.howl = null
        }
    }

    /**
     * @returns {boolean}
     */
    isDesiredPlaying() {
        return this.state.desired === 'playing'
    }

    /**
     * @returns {string}
     */
    _getUrlWithCacheBusting() {
        let url = new URL(this.url);
        let params = new URLSearchParams(url.search.slice(1));
        params.append('cacheBust', '' + Math.floor(Math.random() * Math.floor(99999999999999)))
        url.search = params.toString()
        return url.toString()
    }

    /**
     * @returns {Howl}
     */
    _createHowl() {
        let howl = new Howl({
            src: this._getUrlWithCacheBusting(),
            // A live stream can only be played through HTML5 Audio.
            html5: true,
            autoSuspend: false,
        })
        // See https://github.com/goldfire/howler.js#onevent-function-id
        let events = ['load', 'loaderror', 'playerror', 'play', 'end', 'pause', 'stop', 'mute', 'volume', 'rate', 'seek', 'fade', 'unlock'];
        for (let event of events) {
            howl.on(event, () => {
                this._onHowlEvent(event)
            })
        }
        return howl
    }

    /**
     * @param {String} event
     */
    _onHowlEvent(event) {
        console.debug('Howl event: ', event)
        if (['play'].includes(event)) {
            this.state.setState(null, 'playing')
        }
        if (['loaderror', 'playerror', 'end', 'pause', 'stop'].includes(event)) {
            this.state.setState(null, 'stopped')
        }
    }
}

let toggleEl = document.getElementById('toggleAudio')
let url = 'https://foa-ice-02.ddns.net/renee01.mp3'
let onStateChange = (desired, actual) => {
    toggleEl.dataset['state_desired'] = desired
    toggleEl.dataset['state_actual'] = actual
    updateOpenLinksInNewWindow({
        'stopped': false,
        'playing': true,
    }[desired])
};
let player = new RadioPlayer(url, onStateChange)

toggleEl.addEventListener('click', () => {
    if (!player.isDesiredPlaying()) {
        player.play()
    } else {
        player.stop()
    }
})

/**
 * While audio is playing we open all links in a new tab.
 * Sets target="_blank" for all links, or reverts back to original value.
 * @param {boolean} state
 */
function updateOpenLinksInNewWindow(state) {
    function getOriginalTarget(el) {
        if (el.hasAttribute('data-targetOriginal')) {
            return el.getAttribute('data-targetOriginal')
        } else {
            let targetOriginal
            if (el.hasAttribute('target')) {
                targetOriginal = el.getAttribute('target')
            } else {
                targetOriginal = '_self'
            }
            el.setAttribute('data-targetOriginal', targetOriginal)
            return targetOriginal
        }
    }

    let links = Array.from(document.getElementsByTagName('a'))
        .filter((el) => !el.getAttribute('href').startsWith('#'))
    for (let el of links) {
        let targetOriginal = getOriginalTarget(el)
        if (state) {
            el.setAttribute('target', '_blank')
        } else {
            el.setAttribute('target', targetOriginal)
        }
    }
}
